<template>
  <div class="tnd-input-container">
    <label>
      <div class="tnd-input-label" v-if="inputLabel.length || isLabel">
        <slot name="label">
          {{ inputLabel }}
        </slot>
      </div>
      <div class="tnd-container" :class="inputStatus">
        <div class="tnd-datepicker" v-if="inputType === 'datepicker'">
          <div class="activator" @click="open = !open">
            <calendar-icon />
          </div>
          <date-picker
            class="datepicker"
            v-model="val"
            :open.sync="open"
            type="datetime"
            value-type="format"
            format="YYYY-MM-DD HH:mm"
          />
          <input
            class="input-field"
            :type="type"
            :placeholder="placeholder"
            :value="val"
            disabled
          />
          <div class="close-btn" @click="val = ''">
            <close-icon color="#6F6F6F" />
          </div>
        </div>
        <div class="tnd-select" v-else-if="inputType === 'select'">
          <b-select class="select-field input-field" v-model="val">
            <option
              v-for="(option, index) of options"
              :key="index"
              :value="option.value"
            >
              {{ option.name }}
            </option>
          </b-select>
          <input
            class="input-field is-invisible"
            :type="type"
            :placeholder="placeholder"
            :value="val"
            disabled
          />
          <slot name="after-input">
            <select-icon />
          </slot>
        </div>
        <div class="tnd-dadata" v-else-if="inputType === 'dadata'">
          <vue-dadata
            :onChange="setAddressValue"
            :token="dadataApiKey"
            :autoload="true"
            class="input-field"
          ></vue-dadata>
        </div>
        <div class="tnd-input" v-else>
          <div class="before-input">
            <slot name="before-input" />
          </div>
          <input
            class="input-field"
            :type="type"
            :placeholder="placeholder"
            v-model="val"
          />
          <div class="after-input">
            <slot name="after-input" />
          </div>
        </div>
      </div>
      <p v-if="error" class="error has-text-danger is-size-6-5">{{error}}</p>
      <span class="status-message" :class="inputStatus">{{ message }}</span>
    </label>
  </div>
</template>

<script>
import CalendarIcon from "../icons/CalendarIcon.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ru";
import CloseIcon from "../icons/CloseIcon.vue";
import VueDadata from "vue-dadata";
import SelectIcon from "../icons/SelectIcon.vue";
export default {
  components: {
    DatePicker,
    CalendarIcon,
    CloseIcon,
    VueDadata,
    SelectIcon,
  },
  name: "TndInput",
  props: {
    inputLabel: {
      type: String,
      default: "",
    },
    isLabel: Boolean,
    placeholder: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Date],
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    inputStatus: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    dadataApiKey: process.env.VUE_APP_DADATA_API_KEY,
    open: false,
  }),
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    setAddressValue(e) {
      this.val = e.value;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/variables";
.tnd-input-container {
  position: relative;
  padding-bottom: 14px;

  .error{
    position: absolute;
    line-height: 1rem;
  }

  .tnd-input-label {
    display: block;
    min-height: 33px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #6f6f6f; //TODO добавить цвет в переменну
  }
  .tnd-container {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #ededed; //TODO добавить цвет в переменную
    background: #fbfbfb; //TODO добавить цвет в переменную
    border-radius: 3px;
    padding: 16px;

    .tnd-datepicker {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .activator {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 100%;
        background-color: $purple-bright;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      .datepicker {
        bottom: 0;
        left: 0;
        position: absolute;
        visibility: hidden;
      }

      .input-field {
        padding-left: 40px;
      }

      .close-btn:hover {
        cursor: pointer;
      }
    }

    .tnd-select {
      display: flex;
      width: 100%;
      align-items: center;
      .activator {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      .select-field {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        .select {
          height: 100%;
          width: 100%;
          &::after {
            display: none;
          }

          select {
            height: 100%;
            width: 100%;
            border: none;
            padding-left: 1rem;
            background-color: transparent;
            &:hover,
            &:active,
            &:focus {
              border: none;
            }

            option {
              &:hover {
                background-color: $purple-bright;
              }
            }
          }
        }
      }
    }

    .tnd-dadata {
      display: flex;
      width: 100%;
      .vue-dadata__suggestions {
        margin-top: 20px;
        border: 1px solid #e4e4e4;
        box-sizing: border-box;
        border-radius: 3px;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
      }
    }

    .tnd-input {
      display: flex;
      width: 100%;
      .before-input {
        display: flex;
        justify-content: center;
        align-items: center;
        * {
          margin-right: 12px;
        }
      }

      .after-input {
        display: flex;
        justify-content: center;
        align-items: center;
        * {
          margin-left: 12px;
        }
      }
    }

    &.error {
      border-color: red; //TODO добавить цвет в переменную
    }

    &.success {
      border-color: green; //TODO добавить цвет в переменную
    }

    &:focus-within {
      background-color: #fff;
      border-color: $purple-bright;
      outline: none;
    }

    .input-field,
    input {
      background: transparent;
      border: none;
      outline: none;
      font-size: 16px;
      line-height: 19px;
      height: auto;
      flex: 1;
      &::placeholder {
        color: #c4c4c4; //TODO добавить цвет в переменную
      }

      &:focus {
        box-shadow: none;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }

  .status-message {
    position: absolute;
    font-size: 12px;
    right: 2px;
    bottom: 0;

    &.error {
      color: red; //TODO добавить цвет в переменную
    }

    &.success {
      color: green; //TODO добавить цвет в переменную
    }
  }
}
</style>