<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.08317 1.74047L8.26067 0.917969L4.99984 4.1788L1.739 0.917969L0.916504 1.74047L4.17734 5.0013L0.916504 8.26214L1.739 9.08464L4.99984 5.8238L8.26067 9.08464L9.08317 8.26214L5.82234 5.0013L9.08317 1.74047Z"
      fill="url(#paint0_linear_1059_303)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1059_303"
        x1="0.916504"
        y1="0.917969"
        x2="9.66371"
        y2="1.59609"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="1" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CloseIcon",
    props: {
    color: {
      type: String,
      default: '#A01B5B'
    }
  }
};
</script>